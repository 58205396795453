import React, { useState } from "react";

import styles from "./UploadButton.module.scss";
import Modal from "../../../../components/Modal";

interface UploadButtonProps {
  content: React.ReactNode;
  requiresConfirmation: boolean;
  onFileLoaded(result: string): void;
}

function UploadButton({
  onFileLoaded,
  content,
  requiresConfirmation,
}: UploadButtonProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <div className={styles.container}>
      <Modal
        title={"Foto Hochladen"}
        content={
          "Sind Sie sicher dass Sie das aktuelle Foto überschreiben wollen?"
        }
        confirmButton={
          <label
            htmlFor="fileInput"
            className={"button is-success"}
            onClick={() => {
              setShowModal(false);
            }}
          >
            Ja, neues Foto hochladen
          </label>
        }
        cancelFn={() => {
          setShowModal(false);
        }}
        active={showModal}
      />

      <label
        htmlFor="fileInput"
        className={styles.label}
        onClick={(e) => {
          if (requiresConfirmation) {
            setShowModal(true);
            e.preventDefault();
          }
        }}
      >
        {content}
      </label>
      <input
        id="fileInput"
        type="file"
        className={styles.input}
        onChange={() => {
          const inputElement = document.getElementById(
            "fileInput"
          ) as HTMLInputElement;
          const files = inputElement.files;

          if (files && files[0]) {
            const file = files[0];
            const reader = new FileReader();

            reader.addEventListener(
              "load",
              () => {
                if (typeof reader.result === "string") {
                  onFileLoaded(reader.result);
                } else {
                  console.log("reader.result is not string");
                }

                inputElement.value = "";
              },
              false
            );

            reader.readAsDataURL(file);
          }
        }}
      />
    </div>
  );
}

export default UploadButton;
