import React from "react";
import "@fortawesome/fontawesome-free/css/all.css";

import styles from "./Footer.module.scss";

interface FooterProps {
  children?: React.ReactNode[] | React.ReactNode;
}

function Footer({ children }: FooterProps) {
  return (
    <footer className={styles.footer}>
      <div className={"container"}>
        <div>{children}</div>
        <div className={styles.nav}>
          <ul>
            <li>
              <a href="https://www.plusfoto.at/versand">Versand</a>
            </li>
            <li>
              <a href="https://www.plusfoto.at/datenschutz">Datenschutz</a>
            </li>
            <li>
              <a href="https://www.plusfoto.at/gab">AGB</a>
            </li>
            <li>
              <a href="https://www.plusfoto.at/widerrufsrecht">
                Widerrufsrecht
              </a>
            </li>
            <li>
              <a href="https://www.plusfoto.at/impressum">Impressum</a>
            </li>
          </ul>
        </div>
        {/*<div>
          <div className="columns">
            <div className="column is-one-third">
              <ul>
                <li>FAQ</li>
                <li>Lieferung und Versand</li>
                <li>Rückgabe und Umtausch</li>
              </ul>
            </div>
            <div className="column is-one-third">
              <ul>
                <li>Datenschutz</li>
                <li>AGB</li>
                <li>Impressum</li>
              </ul>
            </div>
            <div className="column is-one-third">
              <div className={styles.socialMedia}>
                Folge uns auf Social Media
                <ul>
                  <li>
                    <a
                      href={"https://facebook.com"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <i className={"fab fa-facebook"} aria-hidden={true} />
                      <span className={"visually-hidden"}>Facebook</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://instagram.com"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <i className={"fab fa-instagram"} aria-hidden={true} />
                      <span className={"visually-hidden"}>Instagram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={"https://pinterest.com"}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <i className={"fab fa-pinterest"} aria-hidden={true} />
                      <span className={"visually-hidden"}>Pinterest</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.copyright}>© 2020 plusfoto</div>
        </div>*/}
      </div>
    </footer>
  );
}

export default Footer;
