import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

import { CART, PAYMENT } from "../../router/routes";
import DisableLink from "../../components/DisableLink";
import ContactForm from "./components/ContactForm";
import DeliverySelection from "./components/DeliverySelection";
import NavIcon from "../../components/NavIcon";
import { useOrders, useOrderData } from "../../db/orders";
import CheckoutLayout from "../../layouts/CheckoutLayout";

function ShippingScreen() {
  const { updateOrder } = useOrders();
  const orderData = useOrderData();

  const [isShipping, setIsShipping] = useState<boolean>(true);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>();

  const setValidity = useCallback((val) => {
    setFormIsValid(val);
  }, []);
  const setValue = useCallback(
    (data: Partial<FormData>) =>
      setFormData((state) => {
        return Object.assign({}, state, data);
      }),
    []
  );

  useEffect(() => {
    if (orderData) {
      setFormData(orderData);
      setIsShipping(orderData.isShipping);
    }
  }, [orderData, setFormData]);

  return (
    <CheckoutLayout
      prevLink={
        <Link to={CART} className={"button"}>
          <NavIcon left />
          Warenkorb
        </Link>
      }
      nextLink={
        <DisableLink
          to={PAYMENT}
          className="button is-success"
          disabled={!formIsValid}
          onClick={() => {
            return new Promise((resolve, reject) => {
              if (formData) {
                updateOrder(
                  Object.assign({}, formData, { isShipping: isShipping })
                ).then(resolve);
              } else {
                reject();
              }
            });
          }}
        >
          Zahlung
          <NavIcon />
        </DisableLink>
      }
      heading={"Kontaktdaten"}
      isShipping={isShipping}
    >
      <DeliverySelection
        handleChange={(e) => {
          setIsShipping(e.target.value === "shipping");
        }}
        isShipping={isShipping}
      />
      <ContactForm
        isShipping={isShipping}
        defaultValues={formData}
        setValidity={setValidity}
        setValue={setValue}
      />
    </CheckoutLayout>
  );
}

export default ShippingScreen;
