export function splitEvery<T>(
  array: Array<T>,
  length: number
): Array<Array<T>> {
  return array.reduce<Array<T[]>>((result, item, index) => {
    if (index % length === 0) result.push([]);
    result[Math.floor(index / length)].push(item);
    return result;
  }, []);
}
