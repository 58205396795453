import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import EditorPage from "./EditorPage";
import { HOME } from "../../router/routes";
import frames from "../../config/frames";
import { useItemContext } from "../../db/items";

interface EditorPageWrapperProps {
  frame?: iFrame;
}

function EditorPageWrapper({ frame }: EditorPageWrapperProps) {
  const location = useLocation();
  const { items, itemLoading } = useItemContext();
  const query = new URLSearchParams(location.search);
  const item = items.find((i) => i.id === query.get("itemId"));
  const itemFrame = item && frames.find((f) => f.id === item.frame);

  if (frame) {
    return <EditorPage frame={frame} />;
  }

  if (itemLoading) {
    return <div>Item Loading</div>;
  }

  if (item && itemFrame) {
    return <EditorPage frame={itemFrame} item={item} />;
  }

  return <Redirect to={HOME} />;
}

export default EditorPageWrapper;
