import React from "react";

import styles from "./Frame.module.scss";
import { ratioToPercent } from "../../utils/math";
import colors from "../../config/colors";
import getTexture from "../../utils/texture";

interface FrameProps {
  dim: Dimension;
  content: string | React.ReactNode;
  layout: Layout;
  colorId: string;
  maxWidth: number;
  overlay?: React.ReactNode;
  noFrame?: boolean;
}

function Frame({
  dim,
  content,
  layout,
  colorId,
  overlay,
  noFrame,
  maxWidth,
}: FrameProps) {
  const {
    height: frameHeight,
    width: frameWidth,
    borderLeft = 0,
    borderTop = 0,
  } = dim;
  const { width: imageWidth, height: imageHeight } = layout;
  const color = colors.find((c) => c.id === colorId);
  return (
    <div
      className={styles.container}
      style={{
        maxWidth: `${Math.min(
          maxWidth / (frameHeight / frameWidth),
          maxWidth
        )}px`,
      }}
    >
      <div
        className={styles.sizer}
        style={Object.assign(
          {
            paddingTop: `${ratioToPercent(frameHeight / frameWidth)}%`,
          },
          color && color.texture
            ? { backgroundImage: `url(${getTexture(color.texture)})` }
            : { backgroundColor: (color && color.value) || "#fff" }
        )}
      />
      {overlay && <div className={styles.overlay}>{overlay}</div>}
      <div className={styles.base}>
        <div
          className={`${styles.interior} ${noFrame ? "" : styles.innerShadow}`}
          style={{
            width: `${ratioToPercent(
              (frameWidth - 2 * borderLeft) / frameWidth
            )}%`,
            left: `${ratioToPercent(borderLeft / frameWidth)}%`,
            height: `${ratioToPercent(
              (frameHeight - 2 * borderTop) / frameHeight
            )}%`,
            top: `${ratioToPercent(borderTop / frameHeight)}%`,
          }}
        >
          <div
            className={styles.image}
            style={{
              width: `${ratioToPercent(
                imageWidth / (frameWidth - 2 * borderLeft)
              )}%`,
              left: `${ratioToPercent(
                ((frameWidth - imageWidth - 2 * borderLeft) * 0.5) /
                  (frameWidth - 2 * borderLeft)
              )}%`,
              height: `${ratioToPercent(
                imageHeight / (frameHeight - 2 * borderTop)
              )}%`,
              top: `${ratioToPercent(
                ((frameHeight - imageHeight - 2 * borderTop) * 0.5) /
                  (frameHeight - 2 * borderTop)
              )}%`,
            }}
          >
            {typeof content == "string" ? (
              <img src={content} alt="" />
            ) : (
              content
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame;
