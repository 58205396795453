import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Catalog from "./components/Catalog";
import frames from "../../config/frames";

function HomePage() {
  return (
    <div className={"app"}>
      <Header />
      <section className="main">
        <Catalog frames={frames} />
      </section>
      <Footer></Footer>
    </div>
  );
}

export default HomePage;
