export const HOME = "/";
export const SHIPPING = "/checkout";
export const EDITOR = "/editor";
export const EDITOR_GALLERY = "/editor/gallery_frame";
export const EDITOR_FLOATING = "/editor/floating_frame";
export const EDITOR_PRINTS = "/editor/prints";
export const EDITOR_TEST_PRODUCT = "/editor/test";
export const PAYMENT = "/payment";
export const CART = "/cart";
export const THANK_YOU = "/thanks";
