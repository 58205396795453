import React from "react";

import styles from "./NavIcon.module.scss";

interface NavIconProps {
  left?: boolean;
}

function NavIcon({ left }: NavIconProps) {
  const iconName = left ? "fa-arrow-left" : "fa-arrow-right";

  return (
    <i
      className={`fas ${iconName} ${styles.icon} ${left ? styles.left : ""}`}
    />
  );
}

export default NavIcon;
