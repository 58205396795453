import React from "react";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

import logo from "../../static/logo.png";
import { CART, HOME } from "../../router/routes";
import styles from "./Header.module.scss";
import { useItemContext } from "../../db/items";

function Header() {
  const { items } = useItemContext();

  return (
    <div className={styles.container}>
      <Link to={HOME}>
        <img
          className={styles.logo}
          src={logo}
          alt="Plusfoto Schriftzug Logo"
        />
      </Link>
      <Link to={CART} className={styles.cart}>
        {items.length > 0 && (
          <div className={styles.items}>
            <span>{items.length}</span>
          </div>
        )}
        <i className={"fas fa-shopping-cart"} aria-hidden={true} />
        <span className={"visually-hidden"}>Warenkorb</span>
      </Link>
    </div>
  );
}

export default Header;
