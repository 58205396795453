import firebase from "firebase/app";
import { useSession } from "../auth";
import { useOrders } from "../db/orders";

export const useUploadImage = (): ((
  dataUrl: string
) => firebase.storage.UploadTask) => {
  const user = useSession();
  const { orderId } = useOrders();

  function uploadImage(dataUrl: string): firebase.storage.UploadTask {
    if (user && user.uid && orderId) {
      const path = `${user.uid}/${orderId}/${Date.now()}.png`;

      return firebase
        .storage()
        .ref(path)
        .putString(dataUrl, "data_url", {
          // to automatically download image when opened
          contentDisposition: "attachment",
          customMetadata: { uid: user.uid },
        });
    } else {
      throw Error("uploadImage: no user or order id");
    }
  }

  return uploadImage;
};
