import React from "react";

import styles from "./CheckoutNav.module.scss";

interface CheckoutNavProps {
  left?: React.ReactNode[] | React.ReactNode;
  right?: React.ReactNode[] | React.ReactNode;
}

function CheckoutNav({ left, right }: CheckoutNavProps) {
  return (
    <div className={`${styles.container}`}>
      {left && <div>{left}</div>}
      {right && <div>{right}</div>}
    </div>
  );
}

export default CheckoutNav;
