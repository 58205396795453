import React from "react";
import { useHistory } from "react-router-dom";

interface DisableLinkProps {
  to: string;
  disabled: boolean;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  onClick?(): Promise<any>;
}

function DisableLink({
  to,
  disabled,
  className,
  children,
  onClick,
}: DisableLinkProps) {
  const history = useHistory();
  return (
    <button
      disabled={disabled}
      className={className}
      onClick={() => {
        if (onClick) {
          onClick().then(() => {
            history.push(to);
          });
        } else {
          history.push(to);
        }
      }}
    >
      {children}
    </button>
  );
}

export default DisableLink;
