import firebase from "firebase/app";

type iUpdatePaymentIntent = (clientSecret: string) => Promise<void>;

export const updatePaymentIntent: iUpdatePaymentIntent = (clientSecret) => {
  const db = firebase.firestore();

  return db.collection("paymentIntent").doc(clientSecret).update({
    status: "client_success",
  });
};
