import React from "react";

import styles from "./Selection.module.scss";

interface Option {
  img: string;
  id: number;
  name?: string;
}

interface SelectionProps {
  options: Array<Option>;
  selection: number;
  setSelection(id: number): void;
}

function Selection({ options, selection, setSelection }: SelectionProps) {
  return (
    <section className={styles.container}>
      {options.map((option) => (
        <div
          key={option.id}
          className={`${styles.option} ${
            selection === option.id ? styles.selected : ""
          }`}
          onClick={() => setSelection(option.id)}
        >
          <img src={option.img} alt="" />
          <div className={styles.subline}>{option.name}</div>
        </div>
      ))}
    </section>
  );
}

export default Selection;
