import {
  EDITOR,
  EDITOR_FLOATING,
  EDITOR_GALLERY,
  EDITOR_PRINTS,
  EDITOR_TEST_PRODUCT,
} from "../router/routes";

export function getEditorRoute(frameId: number): string {
  switch (frameId) {
    case 0:
      return EDITOR_TEST_PRODUCT;
    case 1:
      return EDITOR_GALLERY;
    case 2:
      return EDITOR_FLOATING;
    case 3:
      return EDITOR_PRINTS;
    default:
      return EDITOR;
  }
}
