import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";
import Select from "react-select";

// @ts-ignore
import FilerobotImageEditor from "filerobot-image-editor";

import Header from "../../components/Header";
import Selection from "./components/Selection";
import Frame from "../../components/Frame";
import UploadButton from "./components/UploadButton";
import { useUploadImage } from "../../storage/image";
import { useItems } from "../../db/items";
import { CART } from "../../router/routes";

import styles from "./EditorPage.module.scss";
import ColorSelection from "./components/ColorSelection";
import Footer from "../../components/Footer";

function getColorPrizeMod(frame: iFrame, colorId: string): number {
  if (!frame.colors) return 0;
  const color = frame.colors.find((c) => c.id === colorId);
  if (!color)
    throw Error("Selected color is not available for selected frame.");
  return color.costMod || 0;
}

function getDefaultDim(frame: iFrame, dimQuery: string | null): number {
  if (dimQuery) {
    const defaultDim = frame.dims.find((d) => d.id === parseInt(dimQuery));
    if (defaultDim && defaultDim.id) return defaultDim.id;
  }

  return 1;
}

function getDefaultLayout(
  frame: iFrame,
  dimQuery: string | null,
  layoutQuery: string | null
): number {
  if (dimQuery) {
    const dim = frame.dims.find((d) => d.id === parseInt(dimQuery));

    if (dim) {
      const layouts = dim.layouts;

      if (layoutQuery) {
        const defaultLayout = layouts.find(
          (l) => l.id === parseInt(layoutQuery)
        );
        if (defaultLayout) return defaultLayout.id;
      }
    }
  }

  return 1;
}

function getDefaultColor(frame: iFrame, colorQuery: string | null): string {
  const colors = frame.colors;

  const defaultColor = colors && colors.find((c) => c.id === colorQuery);

  if (defaultColor) return defaultColor.id;

  return frame.colorDefault;
}

interface EditorPageProps {
  frame: iFrame;
  item?: Item;
}

function EditorPage({ frame, item }: EditorPageProps) {
  const uploadImage = useUploadImage();
  const { saveItem, updateItem } = useItems();
  const history = useHistory();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const dimQuery = query.get("dim");
  const layoutQuery = query.get("layout");
  const colorQuery = query.get("color");

  useEffect(() => {
    if (item) {
      setDimSelection(item.dim);
      setLayoutSelection(item.layout);
      setColorSelection(item.color);
      setImageSrc(item.image);
      setUploadedPath(item.path);
    }
  }, [item]);

  const [dimSelection, setDimSelection] = useState<number>(() => {
    return getDefaultDim(frame, dimQuery);
  });
  const [layoutSelection, setLayoutSelection] = useState<number>(() => {
    return getDefaultLayout(frame, dimQuery, layoutQuery);
  });
  const [colorSelection, setColorSelection] = useState<string | null>(() => {
    return getDefaultColor(frame, colorQuery);
  });
  // uploaded image used as input for the image editor
  const [rawImageSrc, setRawImageSrc] = useState<string>();
  // edited image to display in frame
  const [imageSrc, setImageSrc] = useState<string>();
  const [uploadedPath, setUploadedPath] = useState<string>();
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const dim = frame.dims.find((d) => d.id === dimSelection);
  const layout = dim && dim.layouts.find((l) => l.id === layoutSelection);

  // Bug: if language is set to de, it still uses the default values
  const editorConfig = {
    colorScheme: "light",
    tools: ["rotate", "crop"],
    cropPresets: [{ name: "", value: layout && layout.width / layout.height }],
    disableCustomCrop: true,
    language: "en",
    translations: {
      en: {
        "header.image_editor_title": "Plusfoto Editor",
        "toolbar.download": "Speichern",
      },
    },
  };

  return (
    <div className={"app"}>
      <Header />
      <div
        className={`${styles.progressContainer} ${
          isUploading ? "" : styles.hidden
        }`}
      >
        <progress className="progress is-small is-info" max="100" />
      </div>
      {dim && layout && (
        <section className="section main">
          <div className="container">
            <div className="columns">
              <div className="column">
                <Frame
                  content={
                    <UploadButton
                      requiresConfirmation={!!imageSrc}
                      content={
                        imageSrc ? (
                          <img src={imageSrc} alt={""} />
                        ) : (
                          <i
                            className={`fas fa-file-upload ${styles.uploadButtonIcon}`}
                          />
                        )
                      }
                      onFileLoaded={(result) => {
                        setRawImageSrc(result);
                        setShowEditor(true);
                      }}
                    />
                  }
                  dim={dim}
                  layout={layout}
                  colorId={colorSelection || frame.colorDefault}
                  maxWidth={500}
                />
              </div>
              <div className="column">
                <div className={styles.configRow}>
                  <span className={styles.configHeading}>Größe:</span>
                  <Select
                    value={{ value: dim.id, label: dim.name }}
                    onChange={(selectedOption) => {
                      type OptionType = { label: string; value: number };

                      const value = (selectedOption as OptionType).value;
                      setLayoutSelection(1);
                      setDimSelection(value);
                    }}
                    options={frame.dims.map((d) => ({
                      value: d.id,
                      label: d.name,
                    }))}
                  />
                </div>
                {dim.layouts.length > 1 && (
                  <div className={styles.configRow}>
                    <span className={styles.configHeading}>Layout:</span>
                    <Selection
                      options={dim.layouts}
                      selection={layoutSelection}
                      setSelection={setLayoutSelection}
                    />
                  </div>
                )}
                {frame.colors && frame.colors.length > 1 && (
                  <div className={styles.configRow}>
                    <span className={styles.configHeading}>Farbe:</span>
                    <ColorSelection
                      colors={frame.colors.map((c) => c.id)}
                      onColorSelection={setColorSelection}
                    />
                  </div>
                )}
                <div className={styles.configRow}>
                  <span className={styles.configHeading}>
                    Preis:{" "}
                    {(
                      dim.price +
                      getColorPrizeMod(
                        frame,
                        colorSelection || frame.colorDefault
                      )
                    ).toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </span>
                </div>
                <div className={styles.configRow}>
                  <button
                    className={`button ${styles.button}`}
                    disabled={!uploadedPath}
                    onClick={() => {
                      if (item) {
                        updateItem(
                          Object.assign({}, item, {
                            path: uploadedPath,
                            layout: layoutSelection,
                            frame: frame.id,
                            dim: dim.id,
                            color:
                              colorSelection || frame?.colorDefault || "none",
                          })
                        ).then(() => {
                          history.push(CART);
                        });
                      } else if (uploadedPath) {
                        saveItem({
                          path: uploadedPath,
                          dim: dim.id,
                          layout: layoutSelection,
                          frame: frame.id,
                          color:
                            colorSelection || frame?.colorDefault || "none",
                        }).then(() => {
                          history.push(CART);
                        });
                      }
                    }}
                    type="button"
                  >
                    {item ? "Änderungen speichern" : "Artikel hinzufügen"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {showEditor && (
        <FilerobotImageEditor
          show={showEditor}
          src={rawImageSrc}
          config={editorConfig}
          onClose={() => {
            setShowEditor(false);
          }}
          onBeforeComplete={(o: any) => {
            setIsUploading(true);
            uploadImage(o.canvas.toDataURL()).then((snap) => {
              setIsUploading(false);
              setUploadedPath(snap.ref.fullPath);
              snap.ref.getDownloadURL().then((url) => {
                setImageSrc(url);
              });
            });
            return false;
          }}
        />
      )}
      <Footer />
    </div>
  );
}

export default EditorPage;
