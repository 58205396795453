import React from "react";

import { useItemContext } from "../../db/items";
import frames from "../../config/frames";
import colors from "../../config/colors";

import styles from "./CartSummary.module.scss";

interface SummaryItemProps {
  item: {
    frame: number;
    dim: number;
    n: number;
    color: string;
  };
  last: boolean;
}

const shippingCost = 19.8;

function SummaryItem({ item, last }: SummaryItemProps) {
  const frame = frames.find((f) => f.id === item.frame) as iFrame;
  const dim = frame && (frame.dims.find((d) => d.id === item.dim) as Dimension);
  const color = colors.find((c) => c.id === item.color) as Color;

  if (frame && dim) {
    const frameColor =
      frame.colors && frame.colors.find((c) => c.id === item.color);

    const frameName = `${frame.name} - ${dim.name}`;
    const colorName = (color && color.name && ` - (${color.name})`) || "";

    const totalPrice =
      (dim.price + ((frameColor && frameColor.costMod) || 0)) * item.n;

    return (
      <li
        key={`${item.frame}-${item.color}`}
        className={`clearfix ${styles.listItem} ${last ? styles.last : ""}`}
      >
        {item.n}x {frameName} {colorName}
        <span className={styles.price}>
          {totalPrice.toLocaleString(undefined, {
            style: "currency",
            currency: "EUR",
          })}
        </span>
      </li>
    );
  } else {
    throw Error(
      `SummaryItem: no frame with Id ${item.frame} or dimension ${item.dim}`
    );
  }
}

interface CartSummaryProps {
  isShipping: boolean;
}

function CartSummary({ isShipping }: CartSummaryProps) {
  const { items } = useItemContext();
  const itemsAgg = Object.entries(
    items.reduce((dict: { [id: string]: number }, item) => {
      const itemId = `${item.frame} ${item.dim} ${item.color}`;
      if (dict[itemId]) {
        dict[itemId]++;
      } else {
        dict[itemId] = 1;
      }
      return dict;
    }, {})
  ).map((entry) => {
    const [id, n] = entry;
    return {
      frame: parseInt(id.split(" ")[0]),
      dim: parseInt(id.split(" ")[1]),
      color: id.split(" ")[2],
      n,
    };
  });
  const itemsTotal = items.reduce(
    (price, item) => price + (item.price || 0),
    0
  );
  const totalCost = itemsTotal + (isShipping ? shippingCost : 0);

  return (
    <div>
      <ul className={styles.list}>
        {itemsAgg.map((item, i) =>
          SummaryItem({ item, last: i === itemsAgg.length - 1 })
        )}
        {itemsAgg.length > 0 && (
          <>
            {isShipping && (
              <>
                <li className={`clearfix ${styles.listItem}`}>
                  <span className={styles.price}>
                    {itemsTotal.toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </span>
                </li>
                <li className={`clearfix ${styles.listItem} ${styles.last}`}>
                  Versand{" "}
                  <span className={styles.price}>
                    {shippingCost.toLocaleString(undefined, {
                      style: "currency",
                      currency: "EUR",
                    })}
                  </span>
                </li>
              </>
            )}
            <li className={`clearfix ${styles.listItem}`}>
              Gesamtsumme{" "}
              <span className={`${styles.price} ${styles.totalPrice}`}>
                {totalCost.toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </span>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default CartSummary;
