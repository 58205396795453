import React from "react";
import { Link } from "react-router-dom";
import { HOME } from "../../router/routes";

import styles from "./ThankYouScreen.module.scss";

function ThankYouScreen() {
  // const location = useLocation();
  // const shipping = new URLSearchParams(location.search).get("shipping") === "1";

  return (
    <div>
      <img
        src={"/thanks.jpg"}
        alt="Nahaufnahme von einer Schreibmaschine wo in verschiedenen Sprachen 'Danke' geschrieben ist."
      />
      <div className={styles.container}>
        <Link to={HOME}>Zurück zum Shop</Link>
      </div>
    </div>
  );
}

export default ThankYouScreen;
