import React from "react";
import { Link } from "react-router-dom";

import styles from "./ContactInfo.module.scss";
import { SHIPPING } from "../../router/routes";

interface ContactInfoProps {
  data: FormData;
}

function ContactInfo({ data }: ContactInfoProps) {
  const { firstName, lastName, address, zipCode, city, country, email } = data;

  return (
    <div>
      <p className={styles.row}>
        {firstName} {lastName}
      </p>
      <p className={styles.row}>{address}</p>
      <p className={styles.row}>
        {zipCode} {city}
      </p>
      <p className={styles.row}>{country}</p>
      <p className={styles.row}>{email}</p>
      <div className={styles.button}>
        <Link to={SHIPPING} className={`button`}>
          <span className={"icon"}>
            <i className={"fas fa-pencil-alt"} aria-hidden={true} />
          </span>{" "}
          <span>Bearbeiten</span>
        </Link>
      </div>
    </div>
  );
}

export default ContactInfo;
