import React, { FormEvent, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import stripeJs from "@stripe/stripe-js";

import "./style.scss";
import styles from "./StripeForm.module.scss";

interface StripeFormProps {
  clientSecret: string;
  onSuccess: () => void;
}

function StripeForm({ clientSecret, onSuccess }: StripeFormProps) {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event: stripeJs.StripeCardElementChangeEvent) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setProcessing(true);

    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);

      if (cardElement) {
        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });

        if (payload.error) {
          setError(`Zahlung fehlgeschlagen ${payload.error.message}`);
          setProcessing(false);
        } else {
          setError("");
          setProcessing(false);
          setSucceeded(true);
          onSuccess();
        }
      }
    }
  };

  const cardOptions = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div>
        <CardElement options={cardOptions} onChange={handleChange} />
      </div>
      <button
        disabled={disabled || processing || succeeded || !clientSecret}
        className={styles.button}
        type={"submit"}
      >
        {processing ? (
          <div className={styles.spinner} />
        ) : (
          <span>Bezahlen</span>
        )}
      </button>
      {error && (
        <div className={styles.error} role={"alert"}>
          {error}
        </div>
      )}
    </form>
  );
}

export default StripeForm;
