import React from "react";
import colorsDict from "../../../../config/colors";

import styles from "./ColorSelection.module.scss";
import getTexture from "../../../../utils/texture";

interface ColorSelectionProps {
  colors: string[];
  onColorSelection(color: string): void;
}

function ColorSelection({ colors, onColorSelection }: ColorSelectionProps) {
  return (
    <ul>
      {colors.map((colorId) => {
        const color = colorsDict.find((c) => c.id === colorId);
        return (
          color && (
            <li key={color.name} className={styles.listItem}>
              <button
                type="button"
                className={styles.button}
                onClick={() => {
                  onColorSelection(colorId);
                }}
              >
                <div
                  className={styles.circle}
                  style={
                    color.texture
                      ? {
                          backgroundImage: `url(${getTexture(color.texture)})`,
                        }
                      : {
                          backgroundColor: color.value,
                        }
                  }
                />
                {color.name}
              </button>
            </li>
          )
        );
      })}
    </ul>
  );
}

export default ColorSelection;
