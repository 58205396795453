import light from "../static/textures/hell.jpg";
import brown from "../static/textures/braun.jpg";
import black from "../static/textures/schwarz.jpg";
import white from "../static/textures/weiss.jpg";

export default function getTexture(id: string): string {
  switch (id) {
    case "light":
      return light;
    case "brown":
      return brown;
    case "black":
      return black;
    case "white":
      return white;
  }

  return light;
}
