import galleryFrame from "../static/products/gallery_frame.jpg";
import gf_20x20_10x10 from "../static/products/20x20_10x10.jpg";
import gf_20x20_13x13 from "../static/products/20x20-13x13.jpg";
import gf_20x20_full from "../static/products/20x20_voll.jpg";
import gf_20x25_13x13 from "../static/products/20x25_hoch_13x13.jpg";
import gf_20x25_17x13 from "../static/products/20x25_hoch_17x13.jpg";
import gf_20x25_full from "../static/products/20x25_hoch_voll.jpg";
import gf_25x20_13x13 from "../static/products/20x25_quer_13x13.jpg";
import gf_25x20_18x13 from "../static/products/20x25_quer_13x18.jpg";
import gf_25x20_full from "../static/products/20x25_quer_voll.jpg";
import gf_30x30_18x18 from "../static/products/30x30_18x18.jpg";
import gf_30x30_21x21 from "../static/products/30x30_21x21.jpg";
import gf_30x30_full from "../static/products/30x30_voll.jpg";
import gf_30x40_18x24 from "../static/products/30x40_hoch_18x24.jpg";
import gf_30x40_23x17 from "../static/products/30x40_hoch_23x17.jpg";
import gf_30x40_20x20 from "../static/products/30x40_hoch_20x20.jpg";
import gf_30x40_20x30 from "../static/products/30x40_hoch_20x30.jpg";
import gf_30x40_24x32 from "../static/products/30x40_hoch_24x32.jpg";
import gf_30x40_full from "../static/products/30x40_hoch_voll.jpg";
import gf_40x30_13x13 from "../static/products/30x40_quer_13x13.jpg";
import gf_40x30_27x20 from "../static/products/30x40_quer_27x20.jpg";
import gf_40x30_20x20 from "../static/products/30x40_quer_20x20.jpg";
import gf_40x30_30x20 from "../static/products/30x40_quer_30x20.jpg";
import gf_40x30_32x24 from "../static/products/30x40_quer_32x24.jpg";
import gf_40x30_full from "../static/products/30x40_quer_voll.jpg";
import gf_40x40_25x25 from "../static/products/40x40_25x25.jpg";
import gf_40x40_24x18 from "../static/products/40x40_24x18.jpg";
import gf_40x40_20x25 from "../static/products/40x40_20x25.jpg";
import gf_40x40_33x33 from "../static/products/40x40_33x33.jpg";
import gf_40x40_full from "../static/products/40x40_voll.jpg";
import gf_40x50_25x25 from "../static/products/40x50_hoch_25x25.jpg";
import gf_40x50_25x35 from "../static/products/40x50_hoch_25x35.jpg";
import gf_40x50_31x31 from "../static/products/40x50_hoch_31x31.jpg";
import gf_40x50_33x43 from "../static/products/40x50_hoch_33x43.jpg";
import gf_40x50_20x30 from "../static/products/40x50_hoch_20x30.jpg";
import gf_40x50_full from "../static/products/40x50_hoch_voll.jpg";
import gf_40x40_43x33 from "../static/products/40x50_quer_33x43.jpg";
import gf_40x40_35x25 from "../static/products/40x50_quer_35x25.jpg";
import gf_40x40_30x30 from "../static/products/40x50_quer_30x30.jpg";
import gf_40x40_30x20 from "../static/products/40x50_quer_20x30.jpg";
import gf_50x40_full from "../static/products/40x50_quer_voll.jpg";

export default [
  {
    id: 0,
    hide: true,
    name: "Test Produkt",
    desc: "This is a fake product to test the stripe integration",
    img: galleryFrame,
    dims: [
      {
        id: 1,
        name: "Test dimension",
        width: 10,
        height: 10,
        borderLeft: 1,
        borderTop: 1,
        price: 0.5,
        layouts: [
          {
            id: 1,
            name: "Test Layout",
            img: gf_20x20_full,
            width: 10,
            height: 10,
          },
        ],
      },
    ],
    colors: [{ id: "light" }],
    colorDefault: "light",
  },
  {
    id: 1,
    name: "Galerierahmen",
    desc:
      "This is a description of the gallery frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    img: galleryFrame,
    dims: [
      {
        id: 1,
        name: "20cm x 20cm",
        width: 23,
        height: 23,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 37,
        layouts: [
          {
            id: 1,
            name: "10cm x 10cm",
            img: gf_20x20_10x10,
            width: 10,
            height: 10,
          },
          {
            id: 2,
            name: "13cm x 13cm",
            img: gf_20x20_13x13,
            width: 13,
            height: 13,
          },
          {
            id: 3,
            name: "Vollformat",
            img: gf_20x20_full,
            width: 18.6,
            height: 18.6,
          },
        ],
      },
      {
        id: 2,
        name: "20cm x 25cm",
        width: 23,
        height: 28,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 44,
        layouts: [
          // todo: offset
          {
            id: 1,
            name: "13cm x 13cm",
            img: gf_20x25_13x13,
            width: 13,
            height: 13,
          },
          // todo: offset
          {
            id: 2,
            name: "17cm x 13cm",
            img: gf_20x25_17x13,
            width: 17,
            height: 13,
          },
          {
            id: 3,
            name: "Vollformat",
            img: gf_20x25_full,
            width: 18.6,
            height: 23.6,
          },
        ],
      },
      {
        id: 3,
        name: "25cm x 20cm",
        width: 25,
        height: 20,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 44,
        layouts: [
          {
            id: 1,
            name: "13cm x 13cm",
            img: gf_25x20_13x13,
            width: 13,
            height: 13,
          },
          {
            id: 2,
            name: "18cm x 13cm",
            img: gf_25x20_18x13,
            width: 18,
            height: 13,
          },
          {
            id: 3,
            name: "Vollformat",
            img: gf_25x20_full,
            width: 23.6,
            height: 18.6,
          },
        ],
      },
      {
        id: 4,
        name: "30cm x 30cm",
        width: 33,
        height: 33,
        borderLeft: 2.2,
        borderTop: 2.2,
        price: 54,
        layouts: [
          {
            id: 1,
            name: "21cm x 21cm",
            img: gf_30x30_21x21,
            width: 21,
            height: 21,
          },
          {
            id: 2,
            name: "18cm x 18cm",
            img: gf_30x30_18x18,
            width: 18,
            height: 18,
          },
          {
            id: 3,
            name: "Vollformat",
            img: gf_30x30_full,
            width: 28.6,
            height: 28.6,
          },
        ],
      },
      {
        id: 5,
        name: "30cm x 40cm",
        width: 33,
        height: 43,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 66,
        layouts: [
          // todo: offset
          {
            id: 1,
            name: "18cm x 24cm",
            width: 18,
            height: 24,
            img: gf_30x40_18x24,
          },
          // todo: offset
          {
            id: 2,
            name: "23cm x 17cm",
            width: 23,
            height: 17,
            img: gf_30x40_23x17,
          },
          // todo: offset
          {
            id: 3,
            name: "20cm x 20cm",
            width: 20,
            height: 20,
            img: gf_30x40_20x20,
          },
          {
            id: 4,
            name: "20cm x 30cm",
            width: 20,
            height: 30,
            img: gf_30x40_20x30,
          },
          {
            id: 5,
            name: "24cm x 32cm",
            width: 24,
            height: 32,
            img: gf_30x40_24x32,
          },
          {
            id: 6,
            name: "Vollformat",
            width: 28.6,
            height: 38.6,
            img: gf_30x40_full,
          },
        ],
      },
      {
        id: 6,
        name: "40cm x 30cm",
        width: 43,
        height: 33,
        borderLeft: 2.2,
        borderTop: 2.2,
        price: 66,
        layouts: [
          {
            id: 1,
            name: "13cm x 13cm",
            height: 13,
            width: 13,
            img: gf_40x30_13x13,
          },
          {
            id: 2,
            name: "27cm x 20cm",
            width: 27,
            height: 20,
            img: gf_40x30_27x20,
          },
          {
            id: 3,
            name: "20cm x 20cm",
            width: 20,
            height: 20,
            img: gf_40x30_20x20,
          },
          {
            id: 4,
            name: "30cm x 20cm",
            width: 30,
            height: 20,
            img: gf_40x30_30x20,
          },
          {
            id: 5,
            name: "32cm x 24cm",
            width: 32,
            height: 24,
            img: gf_40x30_32x24,
          },
          {
            id: 6,
            name: "Vollformat",
            width: 38.6,
            height: 28.6,
            img: gf_40x30_full,
          },
        ],
      },
      {
        id: 7,
        name: "40cm x 40cm",
        width: 43,
        height: 43,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 79,
        layouts: [
          {
            id: 1,
            name: "25cm x 25cm",
            width: 25,
            height: 25,
            img: gf_40x40_25x25,
          },
          // todo: offset
          {
            id: 2,
            name: "24cm x 18cm",
            width: 24,
            height: 18,
            img: gf_40x40_24x18,
          },
          {
            id: 3,
            name: "20cm x 25cm",
            width: 20,
            height: 25,
            img: gf_40x40_20x25,
          },
          {
            id: 4,
            name: "33cm x 33cm",
            width: 33,
            height: 33,
            img: gf_40x40_33x33,
          },
          {
            id: 5,
            name: "Vollformat",
            width: 38.6,
            height: 38.6,
            img: gf_40x40_full,
          },
        ],
      },
      {
        id: 8,
        name: "40cm x 50cm",
        width: 43,
        height: 53,
        borderLeft: 2.2,
        borderTop: 2.2,
        price: 94,
        layouts: [
          // todo: offset
          {
            id: 1,
            name: "25cm x 25cm",
            width: 25,
            height: 25,
            img: gf_40x50_25x25,
          },
          {
            id: 2,
            name: "25cm x 35cm",
            width: 25,
            height: 35,
            img: gf_40x50_25x35,
          },
          {
            id: 3,
            name: "31cm x 31cm",
            width: 31,
            height: 31,
            img: gf_40x50_31x31,
          },
          {
            id: 4,
            name: "33cm x 43cm",
            width: 33,
            height: 43,
            img: gf_40x50_33x43,
          },
          {
            id: 5,
            name: "20cm x 30cm",
            width: 20,
            height: 30,
            img: gf_40x50_20x30,
          },
          {
            id: 6,
            name: "Vollformat",
            width: 38.6,
            height: 48.6,
            img: gf_40x50_full,
          },
        ],
      },
      {
        id: 9,
        name: "50cm x 40cm",
        width: 53,
        height: 43,
        borderTop: 2.2,
        borderLeft: 2.2,
        price: 94,
        layouts: [
          {
            id: 1,
            name: "43cm x 33cm",
            width: 43,
            height: 33,
            img: gf_40x40_43x33,
          },
          {
            id: 2,
            name: "35cm x 25cm",
            width: 35,
            height: 25,
            img: gf_40x40_35x25,
          },
          {
            id: 3,
            name: "30cm x 30cm",
            width: 30,
            height: 30,
            img: gf_40x40_30x30,
          },
          // todo: offset
          {
            id: 4,
            name: "30cm x 20cm",
            width: 30,
            height: 20,
            img: gf_40x40_30x20,
          },
          {
            id: 5,
            name: "Vollformat",
            width: 48.6,
            height: 38.6,
            img: gf_50x40_full,
          },
        ],
      },
    ],
    colors: [
      { id: "light" },
      { id: "brown" },
      { id: "black" },
      { id: "white" },
    ],
    colorDefault: "light",
  },
  /*  {
    id: 2,
    name: "Floating Frame",
    desc:
      "This is a description of the floating frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    img: floatingFrame,
    dims: [
      {
        id: 1,
        name: "20cm x 20cm",
        width: 20,
        height: 20,
        borderTop: 3,
        borderLeft: 3,
        price: 30,
        layouts: [
          {
            id: 1,
            name: "10cm x 10cm",
            img: floatingFrame,
            width: 10,
            height: 10,
          },
          {
            id: 2,
            name: "Full Bleed",
            img: floatingFrame,
            width: 14,
            height: 14,
          },
        ],
      },
      {
        id: 2,
        name: "40cm x 40cm",
        width: 40,
        height: 40,
        borderTop: 3,
        borderLeft: 3,
        price: 50,
        layouts: [
          {
            id: 1,
            name: "30cm x 30cm",
            img: floatingFrame,
            width: 30,
            height: 30,
          },
          {
            id: 2,
            name: "Full Bleed",
            img: floatingFrame,
            width: 34,
            height: 34,
          },
        ],
      },
    ],
    colors: [{ id: "white" }, { id: "black" }],
    colorDefault: "white",
  },
  {
    id: 3,
    name: "Prints",
    desc:
      "This is a description of the floating frame. \n Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque, repellendus.",
    img: prints,
    noFrame: true,
    dims: [
      {
        id: 1,
        name: "15cm x 10cm",
        width: 15,
        height: 10,
        price: 0.75,
        layouts: [
          {
            id: 1,
            name: "Full Bleed",
            img: prints,
            width: 15,
            height: 10,
          },
        ],
      },
    ],
    colors: [
      {
        id: "none",
      },
    ],
    colorDefault: "none",
  },*/
];
