import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.css";

import Frame from "../../../../components/Frame";
import frames from "../../../../config/frames";
import { colors } from "../../../../config/colors";
import styles from "./ItemList.module.scss";
import Modal from "../../../../components/Modal";
import { useItems } from "../../../../db/items";
import { EDITOR } from "../../../../router/routes";

interface ItemListProps {
  items: Item[];
}

function ItemList({ items }: ItemListProps) {
  const history = useHistory();
  const { deleteItem } = useItems();

  const [toDelete, setToDelete] = useState<string>();

  return (
    <div className={styles.page}>
      {items
        .map((item) =>
          Object.assign(item, { createdAt: item.createdAt || Date.now() })
        )
        .sort((a, b) => b.createdAt - a.createdAt)
        .map((item, i) => {
          const frame = frames.find((f) => f.id === item.frame) as iFrame;
          const dim = frame && frame.dims.find((d) => d.id === item.dim);
          const layout = dim && dim.layouts.find((l) => l.id === item.layout);
          const color = colors.find((c) => c.id === item.color);
          const editLinkClick = () => {
            history.push({
              pathname: EDITOR,
              search: new URLSearchParams({
                itemId: item.id,
              }).toString(),
            });
          };

          return (
            <div
              className={`columns ${styles.row} ${
                i === items.length - 1 ? styles.noBorder : ""
              }`}
              key={`item-${item.id}`}
            >
              <div className={`column`}>
                <div onClick={editLinkClick} className={styles.image}>
                  {dim && layout ? (
                    <Frame
                      colorId={item.color}
                      content={item.image}
                      dim={dim}
                      layout={layout}
                      maxWidth={200}
                    />
                  ) : (
                    <img src={item.image} alt="" />
                  )}
                </div>
              </div>
              <div className={`column ${styles.maxWidth}`}>
                <div className={styles.frameName}>{frame.name}</div>
                {dim && <div>Größe: {dim.name}</div>}
                {color && <div>Farbe: {color.name}</div>}
              </div>
              <div className={`column ${styles.maxWidth}`}>
                <div className={styles.rightColumn}>
                  <div className={styles.actionButtons}>
                    <div className={styles.actionButton}>
                      <button onClick={() => setToDelete(item.id)}>
                        <span>Löschen</span>
                        <i className={"fas fa-trash-alt"} aria-hidden={true} />
                      </button>
                    </div>
                    <div className={styles.actionButton}>
                      <button onClick={editLinkClick}>
                        <span>Bearbeiten</span>
                        <i className={"fas fa-pencil-alt"} aria-hidden={true} />
                      </button>
                    </div>
                  </div>
                  <span className={styles.price}>
                    {item.price &&
                      item.price.toLocaleString(undefined, {
                        style: "currency",
                        currency: "EUR",
                      })}
                  </span>
                </div>
              </div>
            </div>
          );
        })}

      <Modal
        title={"Löschen Bestätigen"}
        content={"Sind Sie sicher dass Sie diesen Artikel löschen wollen?"}
        confirmButton={
          <button
            className="button is-danger"
            onClick={() => {
              if (toDelete) {
                deleteItem(toDelete);
              }
              setToDelete(undefined);
            }}
          >
            Löschen
          </button>
        }
        cancelFn={() => setToDelete(undefined)}
        active={!!toDelete}
      />
    </div>
  );
}

export default ItemList;
