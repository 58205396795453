import React from "react";

import styles from "./Modal.module.scss";

interface ModalProps {
  title: string;
  content: string;
  confirmButton: React.ReactNode;
  cancelFn(): void;
  active: boolean;
  cancelText?: string;
}

function Modal({
  title,
  content,
  confirmButton,
  cancelFn,
  cancelText,
  active,
}: ModalProps) {
  return (
    <div className={`modal ${active ? "is-active" : ""}`}>
      <div className="modal-background" />
      <div className={`modal-card ${styles.modal}`}>
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button className="delete" aria-label="close" onClick={cancelFn} />
        </header>
        <section className="modal-card-body">{content}</section>
        <footer className="modal-card-foot">
          <div className={styles.buttons}>
            <div className={styles.button}>{confirmButton}</div>
            <div className={styles.button}>
              <button className="button" onClick={cancelFn}>
                {cancelText || "Abbrechen"}
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Modal;
