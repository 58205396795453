import React from "react";
import Header from "../../components/Header";
import CheckoutNav from "../../components/CheckoutNav";

import styles from "./CheckoutFlow.module.scss";
import CartSummary from "../../components/CartSummary";
import Footer from "../../components/Footer";
import ContactInfo from "../../components/ContactInfo";

interface CheckoutLayoutProps {
  children: React.ReactNode | React.ReactNode[];
  prevLink: React.ReactNode;
  nextLink?: React.ReactNode;
  heading: string;
  isShipping: boolean;
  contactData?: FormData;
}

function CheckoutLayout({
  children,
  prevLink,
  nextLink,
  heading,
  isShipping,
  contactData,
}: CheckoutLayoutProps) {
  const checkoutNav = (
    <CheckoutNav left={prevLink} right={nextLink || <div />} />
  );

  return (
    <div className={"app"}>
      <Header />
      <div className="container">
        <div className="section main">
          {checkoutNav}
          <div className={styles.main}>
            <div className={`columns`}>
              <div className="column is-two-thirds">
                <h1 className={styles.heading}>{heading}</h1>
                {children}
              </div>
              <div className="column">
                <div className={styles.cartSummaryContainer}>
                  <h1 className={styles.heading}>Warenkorb</h1>
                  <CartSummary isShipping={isShipping} />
                </div>

                {contactData && (
                  <>
                    <h1 className={styles.heading}>Kontaktdaten</h1>
                    <ContactInfo data={contactData} />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className={`mobile-only ${styles.bottomNav}`}>{checkoutNav}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CheckoutLayout;
