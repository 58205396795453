export const colors: Color[] = [
  {
    id: "light",
    name: "Hell",
    texture: "light",
  },
  {
    id: "brown",
    name: "Braun",
    texture: "brown",
  },
  {
    id: "black",
    name: "Schwarz",
    texture: "black",
  },
  {
    id: "white",
    name: "Weiß",
    texture: "white",
  },
  {
    id: "none",
    name: "",
  },
];

export default colors;
