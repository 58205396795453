import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

interface ContactFormProps {
  defaultValues?: FormData;
  setValidity(valid: boolean): void;
  setValue(data: Partial<FormData>): void;
  isShipping: boolean;
}

const formKeys = [
  "firstName",
  "lastName",
  "address",
  "zipCode",
  "city",
  // "country",
  "email",
];

function ContactForm({
  defaultValues,
  setValidity,
  setValue,
  isShipping,
}: ContactFormProps) {
  const { register, formState, errors, getValues, triggerValidation } = useForm<
    FormData
  >({
    mode: "onChange",
  });
  const { isValid } = formState;

  useEffect(() => {
    if (
      defaultValues &&
      Object.keys(defaultValues)
        .filter((k) => formKeys.indexOf(k) !== -1)
        // @ts-ignore
        .reduce((has_value, k) => has_value || defaultValues[k], false)
    ) {
      triggerValidation();
    }
  }, [defaultValues, triggerValidation, isShipping]);

  useEffect(() => {
    setValidity(isValid);
  }, [isValid, setValidity]);

  // set default values (country)
  useEffect(() => {
    setValue(getValues());
  }, [getValues, setValue]);

  return (
    <form>
      <div className="field">
        <div className="field-body">
          <div className="field">
            <label htmlFor="firstName" className={"label"}>
              Vorname:
            </label>
            <div className="control">
              <input
                className={`input ${errors.firstName && "is-danger"}`}
                type="text"
                name={"firstName"}
                defaultValue={defaultValues && defaultValues.firstName}
                onChange={(e) => setValue({ firstName: e.target.value })}
                ref={register({
                  required: "Pflichtfeld",
                })}
              />
            </div>
            {errors.firstName && (
              <p className="help is-danger">{errors.firstName.message}</p>
            )}
          </div>
          <div className="field">
            <label htmlFor="lastName" className={"label"}>
              Nachname:
            </label>
            <div className="control">
              <input
                className={`input ${errors.lastName && "is-danger"}`}
                type="text"
                name={"lastName"}
                defaultValue={defaultValues && defaultValues.lastName}
                onChange={(e) => setValue({ lastName: e.target.value })}
                ref={register({
                  required: "Pflichtfeld",
                })}
              />
            </div>
            {errors.lastName && (
              <p className="help is-danger">{errors.lastName.message}</p>
            )}
          </div>
        </div>
      </div>
      <div className="field">
        <fieldset disabled={!isShipping}>
          <div className="field">
            <label htmlFor="address" className={"label"}>
              Adresse:
            </label>
            <div className="control">
              <input
                type="text"
                className={`input ${errors.address && "is-danger"}`}
                name={"address"}
                defaultValue={defaultValues && defaultValues.address}
                onChange={(e) => setValue({ address: e.target.value })}
                ref={register({
                  required: isShipping && "Pflichtfeld",
                })}
              />
            </div>
            {errors.address && (
              <p className="help is-danger">{errors.address.message}</p>
            )}
          </div>
        </fieldset>
      </div>
      <div className="field">
        <fieldset disabled={!isShipping}>
          <div className="field">
            <div className="field-body">
              <div className="field">
                <label htmlFor="zipCode" className="label">
                  PLZ:
                </label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.zipCode && "is-danger"}`}
                    name={"zipCode"}
                    defaultValue={defaultValues && defaultValues.zipCode}
                    onChange={(e) =>
                      setValue({ zipCode: parseInt(e.target.value) })
                    }
                    ref={register({
                      required: isShipping && "Pflichtfeld",
                      pattern: {
                        value: /^\d{4}$/,
                        message: "Ungültige Postleitzahl",
                      },
                    })}
                  />
                </div>
                {errors.zipCode && (
                  <p className="help is-danger">{errors.zipCode.message}</p>
                )}
              </div>
              <div className="field">
                <label htmlFor="city" className="label">
                  Ort
                </label>
                <div className="control">
                  <input
                    type="text"
                    className={`input ${errors.city && "is-danger"}`}
                    name={"city"}
                    defaultValue={defaultValues && defaultValues.city}
                    onChange={(e) => setValue({ city: e.target.value })}
                    ref={register({
                      required: isShipping && "Pflichtfeld",
                    })}
                  />
                </div>
                {errors.city && (
                  <p className="help is-danger">{errors.city.message}</p>
                )}
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div className="field">
        <fieldset disabled>
          <div className="field">
            <label htmlFor="country" className={"label"}>
              Land:
            </label>
            <div className="control">
              <input
                type="text"
                className={`input ${errors.city && "is-danger"}`}
                name={"country"}
                defaultValue={"Österreich"}
                onChange={(e) => setValue({ country: e.target.value })}
                ref={register({
                  required: isShipping && "Pflichtfeld",
                  validate: (value) => value === "Österreich",
                })}
              />
            </div>
            {errors.country && (
              <p className="help is-danger">{errors.country.message}</p>
            )}
          </div>
        </fieldset>
      </div>
      <div className="field">
        <label htmlFor="email" className="label">
          Email:
        </label>
        <div className="control">
          <input
            type="text"
            className={`input ${errors.email && "is-danger"}`}
            name={"email"}
            defaultValue={defaultValues && defaultValues.email}
            onChange={(e) => setValue({ email: e.target.value })}
            ref={register({
              required: "Pflichtfeld",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Ungültige Email Adresse",
              },
            })}
          />
        </div>
        {errors.email && (
          <p className="help is-danger">{errors.email.message}</p>
        )}
      </div>
    </form>
  );
}

export default ContactForm;
