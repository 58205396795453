import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { useOrders } from "../../db/orders";
import { useSession } from "../../auth";
import StripeForm from "./components/StripeForm";
import { SHIPPING, THANK_YOU } from "../../router/routes";
import CheckoutLayout from "../../layouts/CheckoutLayout";
import NavIcon from "../../components/NavIcon";
import { apiRequest } from "../../utils/http";
import { updatePaymentIntent } from "../../db/payment";

function PaymentScreen() {
  const { orderData } = useOrders();
  const user = useSession();
  const history = useHistory();
  const [clientSecret, setClientSecret] = useState<string>("");

  useEffect(() => {
    if (user) {
      apiRequest("/payment", user)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setClientSecret(data.client_secret);
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }, [user]);

  let isShipping = true;
  if (orderData) {
    isShipping = orderData.isShipping;
  }

  return (
    <CheckoutLayout
      contactData={orderData}
      isShipping={isShipping}
      prevLink={
        <Link to={SHIPPING} className={"button"}>
          <NavIcon left />
          Kontaktdaten
        </Link>
      }
      heading={"Zahlungsinformationen"}
    >
      <StripeForm
        clientSecret={clientSecret}
        onSuccess={() => {
          updatePaymentIntent(clientSecret).then(() => {
            history.push({
              pathname: THANK_YOU,
              search: new URLSearchParams({
                shipping: isShipping ? "1" : "0",
              }).toString(),
            });
          });
        }}
      />
    </CheckoutLayout>
  );
}

export default PaymentScreen;
