import { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";

type UserOrNull = firebase.User | null;

export const UserContext = createContext<UserOrNull>(null);

export const useSession = (): UserOrNull => {
  return useContext(UserContext);
};

export const useAuth = (): UserOrNull => {
  const [state, setState] = useState(firebase.auth().currentUser);

  function onChange(user: UserOrNull) {
    setState(user);
  }

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(onChange);
  }, []);

  return state;
};
