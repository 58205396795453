import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as ROUTES from "./routes";
import CartPage from "../pages/CartPage";
import ShippingScreen from "../pages/ShippingScreen";
import EditorPage from "../pages/EditorPage";
import PaymentScreen from "../pages/PaymentScreen";
import HomePage from "../pages/HomePage";
import frames from "../config/frames";
import ThankYouScreen from "../pages/ThankYouScreen";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.EDITOR_TEST_PRODUCT}>
          <EditorPage frame={frames[0]} />
        </Route>
        <Route path={ROUTES.EDITOR_GALLERY}>
          <EditorPage frame={frames[1]} />
        </Route>
        <Route path={ROUTES.EDITOR}>
          <EditorPage />
        </Route>
        <Route path={ROUTES.SHIPPING}>
          <ShippingScreen />
        </Route>
        <Route path={ROUTES.CART}>
          <CartPage />
        </Route>
        <Route path={ROUTES.PAYMENT}>
          <PaymentScreen />
        </Route>
        <Route path={ROUTES.THANK_YOU}>
          <ThankYouScreen />
        </Route>
        <Route path={ROUTES.HOME}>
          <HomePage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
