import firebase from "firebase/app";

function getFirebaseUrl(): string {
  return "https://us-central1-fotoplus-a41df.cloudfunctions.net/";
}

export function apiRequest(route: string, currentUser: firebase.User) {
  return currentUser.getIdToken().then((token) => {
    return fetch(`${getFirebaseUrl()}/api${route}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  });
}
