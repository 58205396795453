import React from "react";

import styles from "./CopyrightCheckbox.module.scss";

interface CopyrightCheckboxProps {
  copyright: boolean;
  setCopyright(value: boolean): void;
}

function CopyrightCheckbox({
  copyright,
  setCopyright,
}: CopyrightCheckboxProps) {
  return (
    <div className={styles.copyright}>
      <label className={"checkbox"}>
        <input
          checked={copyright}
          type="checkbox"
          onChange={(e) => setCopyright(e.target.checked)}
        />{" "}
        Ich habe die Rechte an den Bilddaten oder das Einverständnis des/der
        Urheber_in, diese zu verwenden.
      </label>
    </div>
  );
}

export default CopyrightCheckbox;
