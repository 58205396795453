import React, { useState } from "react";

import Header from "../../components/Header";
import { SHIPPING } from "../../router/routes";
import Footer from "../../components/Footer";
import DisableLink from "../../components/DisableLink";
import { useItemContext } from "../../db/items";
import ItemList from "./components/ItemList";
import CheckoutNav from "../../components/CheckoutNav";
import CopyrightCheckbox from "./components/CopyrightCheckbox";

import styles from "./CartPage.module.scss";

function CartPage() {
  const { items } = useItemContext();
  const [copyright, setCopyright] = useState<boolean>(false);

  const checkoutNav = (
    <CheckoutNav
      right={
        <div className={styles.checkoutNav}>
          <span className={styles.totalSum}>
            Gesamtsumme:{" "}
            {items
              .reduce((prev, cur) => prev + (cur.price || 0), 0)
              .toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
              })}
          </span>{" "}
          <DisableLink
            to={SHIPPING}
            className={`button is-success is-normal`}
            disabled={items.length === 0 || !copyright}
          >
            Jetzt bestellen
          </DisableLink>
        </div>
      }
    />
  );

  return (
    <div className={"app"}>
      <Header />
      <section className={`main container section`}>
        {items.length > 0 ? (
          <div>
            {items.length > 1 && (
              <div className={`${styles.topCheckoutNav} is-hidden-tablet`}>
                <CopyrightCheckbox
                  copyright={copyright}
                  setCopyright={setCopyright}
                />
                {checkoutNav}
              </div>
            )}
            <ItemList items={items} />
            <CopyrightCheckbox
              copyright={copyright}
              setCopyright={setCopyright}
            />
            {checkoutNav}
          </div>
        ) : (
          <div>No Items</div>
        )}
      </section>
      <Footer />
    </div>
  );
}

export default CartPage;
