import { createContext, useContext, useEffect, useState } from "react";
import firebase from "firebase/app";
import { useAuth } from "../auth";

export const OrderDataContext = createContext<FormData | undefined>(undefined);

export const useOrderData = (): FormData | undefined => {
  return useContext(OrderDataContext);
};

type iUpdateOrder = (data: FormData) => Promise<void>;

interface OrderHook {
  orderId: string;
  updateOrder: iUpdateOrder;
  orderData: FormData | undefined;
}

export const useOrders = (): OrderHook => {
  const user = useAuth();
  const [orderId, setOrderId] = useState<string>("");
  const [orderData, setOrderData] = useState<FormData>();

  useEffect(() => {
    if (user) {
      const db = firebase.firestore();

      return db
        .collection("users")
        .doc(user.uid)
        .onSnapshot((doc) => {
          const userData = doc.data();
          if (userData) {
            setOrderId(userData.openOrderId);
          } else {
            console.error("No User Data available", doc);
          }
        });
    }
  }, [user]);

  useEffect(() => {
    if (user && orderId) {
      const db = firebase.firestore();

      return db
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .onSnapshot((doc) => {
          const data = doc.data() as FormData;
          if (data) {
            setOrderData(data);
          }
        });
    }
  }, [user, orderId]);

  const updateOrder: iUpdateOrder = (data) => {
    if (user && orderId) {
      const db = firebase.firestore();

      return db
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .update(data);
    } else {
      throw Error("updateOrder: no user or orderID");
    }
  };

  return {
    orderId,
    orderData,
    updateOrder,
  };
};
