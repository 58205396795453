import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { fbConfig, stripeKey } from "./config";
import Router from "./router";
import { useAuth, UserContext } from "./auth";
import { useOrders, OrderDataContext } from "./db/orders";
import { useItems, ItemContext } from "./db/items";

import "./style.scss";

firebase.initializeApp(fbConfig);

let stripePromise: Promise<Stripe | null>;
if (stripeKey) {
  stripePromise = loadStripe(stripeKey);
} else {
  throw Error("No Stripe Key found");
}

function App() {
  const user = useAuth();
  const { orderData } = useOrders();
  const { items, itemLoading } = useItems();

  useEffect(() => {
    if (!user) {
      firebase.auth().signInAnonymously();
    } else {
      console.log("user logged in", user.uid);
    }
  }, [user]);

  return (
    <UserContext.Provider value={user}>
      <OrderDataContext.Provider value={orderData}>
        <ItemContext.Provider value={{ items, itemLoading }}>
          <Elements stripe={stripePromise}>
            <Router />
          </Elements>
        </ItemContext.Provider>
      </OrderDataContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
