import React from "react";
import { Link } from "react-router-dom";

import { splitEvery } from "../../../../utils/array";
import styles from "./Catalog.module.scss";
import { getEditorRoute } from "../../../../utils/frames";

interface CatalogProps {
  frames: iFrame[];
}

function Catalog({ frames }: CatalogProps) {
  const framesFiltered = frames.filter((f) => !f.hide);
  const grid = splitEvery(framesFiltered, 3).map((framesChunk, i) => (
    <div className={"columns"} key={`row-${i}`}>
      {framesChunk.map((frame) => (
        <div className={`column is-one-third`} key={frame.id}>
          <Link to={getEditorRoute(frame.id)} className={styles.gridElement}>
            <div className={styles.frame}>
              <img src={frame.img} alt="" />
            </div>
            <h2 className={styles.name}>{frame.name}</h2>
            <h3 className={styles.price}>
              ab{" "}
              {Math.min(...frame.dims.map((d) => d.price)).toLocaleString(
                undefined,
                {
                  style: "currency",
                  currency: "EUR",
                }
              )}
            </h3>
          </Link>
        </div>
      ))}
    </div>
  ));

  return <div className={"container"}>{grid}</div>;
}

export default Catalog;
