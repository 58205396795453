import React, { ChangeEvent } from "react";

import styles from "./DeliverySelection.module.scss";

interface DeliverySelectionProps {
  isShipping: boolean;
  handleChange(event: ChangeEvent<HTMLInputElement>): void;
}

function DeliverySelection({
  isShipping,
  handleChange,
}: DeliverySelectionProps) {
  return (
    <div className={`${styles.container}`}>
      <div className={styles.desc}>
        Wie möchten Sie die ausgewählten Artikel erhalten?
      </div>
      <label className={`radio ${styles.option}`}>
        <input
          className={styles.input}
          type="radio"
          name={"delivery"}
          checked={isShipping}
          onChange={handleChange}
          value={"shipping"}
        />
        <span className={styles.label}>Versand</span>
      </label>
      <label className={`radio ${styles.option}`}>
        <input
          checked={!isShipping}
          className={styles.input}
          type="radio"
          name={"delivery"}
          value={"pickup"}
          onChange={handleChange}
        />
        <span className={styles.label}>Abholung</span>
      </label>
    </div>
  );
}

export default DeliverySelection;
