import { createContext, useContext, useEffect, useState } from "react";
import { useOrders } from "./orders";
import { useAuth } from "../auth";
import * as firebase from "firebase/app";

interface iItemContext {
  items: Array<Item>;
  itemLoading: boolean;
}

export const ItemContext = createContext<iItemContext>({
  items: [],
  itemLoading: true,
});

export const useItemContext = (): iItemContext => {
  return useContext(ItemContext);
};

type iSaveItem = (
  item: ItemClient
) => Promise<firebase.firestore.DocumentReference>;

type iUpdateItem = (item: Item) => Promise<void>;

type iDeleteItem = (itemId: string) => void;

interface ItemsHook {
  items: Array<Item>;
  itemLoading: boolean;
  saveItem: iSaveItem;
  updateItem: iUpdateItem;
  deleteItem: iDeleteItem;
}

export const useItems = (): ItemsHook => {
  const [items, setItems] = useState<Array<Item>>([]);
  const [itemLoading, setItemLoading] = useState<boolean>(true);
  const user = useAuth();
  const { orderId } = useOrders();

  useEffect(() => {
    if (user && orderId) {
      const db = firebase.firestore();

      return db
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .collection("items")
        .onSnapshot((snap) => {
          const items: Array<Item> = [];
          const promises: Promise<any>[] = [];

          snap.forEach((doc) => {
            const item = doc.data();

            promises.push(
              firebase
                .storage()
                .ref(item.path)
                .getDownloadURL()
                .then((url) => {
                  items.push({
                    image: url,
                    path: item.path,
                    frame: item.frame,
                    dim: item.dim,
                    layout: item.layout,
                    color: item.color,
                    createdAt: item.createdAt,
                    price: item.price,
                    id: doc.id,
                  });
                })
            );
          });

          return Promise.all(promises)
            .then(() => {
              setItems(items);
            })
            .then(() => setItemLoading(false));
        });
    }
  }, [user, orderId]);

  const saveItem: iSaveItem = (item) => {
    console.log("saving item", item.path);
    if (user && orderId) {
      return firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .collection("items")
        .add(item);
    } else {
      throw Error("saveItem(): no user or orderId");
    }
  };

  const updateItem: iUpdateItem = (item) => {
    if (user && orderId) {
      delete item.image;

      return firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .collection("items")
        .doc(item.id)
        .update(item);
    } else {
      throw Error("updateItem(): no user or orderId");
    }
  };

  const deleteItem: iDeleteItem = (id) => {
    if (user && orderId) {
      return firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .collection("orders")
        .doc(orderId)
        .collection("items")
        .doc(id)
        .delete();
    } else {
      throw Error("deleteItem(): no user or orderId");
    }
  };

  return {
    items,
    itemLoading,
    saveItem,
    updateItem,
    deleteItem,
  };
};
